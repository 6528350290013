import HomepageMain from "../../components/HomepageMain";
import Footer from "../../components/Footer";

function Homepage() {
  return (
    <div>
      <HomepageMain/>
      <Footer />
    </div>
  );
}

export default Homepage;